<template>
  <div class="extration-bdd-frais">
    <b-button
      size="sm"
      class="button-export-style  ml-2"
      v-b-modal.validate-statut-installateur-modal
      title="Modifier les statuts"
    >
      <font-awesome-icon icon="pen"
    /></b-button>
    <!-- Validation statue installateur Modal -->
    <b-modal
      ref="validate-statut-installateur-modal"
      id="validate-statut-installateur-modal"
      no-close-on-backdrop
      :hide-footer="true"
      :title="'Modification globale de statut ' + computedStatueToUpdate"
      @hidden="hideModal('validate-statut-installateur-modal')"
      modal-class="modal-validation-statut-frais"
    >
      <b-form-group
        id="fieldset-horizontal-type"
        label-cols-sm="5"
        label-cols-lg="3"
        content-cols-sm
        content-cols-lg="9"
        label="Statut: "
        label-for="type-modal"
      >
        <b-form-select
          id="fieldset-horizontal-type"
          v-model="statutSelected"
          :options="computedListType"
          required
          class="b-form-select-raduis "
        ></b-form-select>
      </b-form-group>
      <div class="form-modal-custom-style mt-2">
        <div class="messageError">
          <div v-if="error" class="error">
            <ul v-if="Array.isArray(error)">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ error }}</span>
          </div>
        </div>
        <div class="actionModel">
          <b-button
            class="button-valide-style "
            @click="handleUpdateStatutGlobale"
          >
            <span>
              Générer
              <div v-if="loading" class="loading ml-2">
                <div class="spinner-border" role="status"></div></div
            ></span>
          </b-button>
        </div>
      </div>
    </b-modal>
    <!-- END Validation Statue Installateur Modal -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    filterType: {
      default: null
    },
    dataToUse: { required: true },
    curentInterface: { default: 'frais' }
  },
  data() {
    return {
      statutSelected: 'paye',
      error: null,
      loading: false,
      ListType: [
        { value: 'a paye', text: 'Facture à payer' },
        { value: 'paye', text: 'Facture payée' },
        { value: 'annulee', text: 'Facture annulée' }
      ]
    };
  },
  methods: {
    ...mapActions(['validateStatueInstallateurGlobale']),
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    resetModal() {
      this.error = null;
      this.loading = false;
      this.statutSelected = 'paye';
    },
    async handleUpdateStatutGlobale() {
      this.loading = true;
      var bodyFormData = new FormData();
      bodyFormData.append('type', this.computedRole);
      bodyFormData.append('statue', this.statutSelected);
      let indexToUse = 0;
      for (let j = 0; j < this.dataToUse.length; j++) {
        for (let i = 0; i < this.dataToUse[j].projects.length; i++) {
          if (
            this.dataToUse[j].projects[i].check == true &&
            this.dataToUse[j].projects[i][this.computedFields] &&
            this.dataToUse[j].projects[i][this.computedFields] != null
          ) {
            bodyFormData.append(
              'ids[' + [indexToUse] + ']',
              this.dataToUse[j].projects[i][this.computedFields]
            );
            indexToUse = indexToUse + 1;
          }
        }
      }
      let testValue = null;
      for (var pair of bodyFormData.entries()) {
        if (pair[0] && pair[0].includes('ids')) {
          testValue = true;
        }
      }
      if (testValue == null) {
        this.error =
          "Sélectionne au moins un dossier afin d'effectuer un changement de statut global.";
        this.loading = false;

        return;
      }
      const response = await this.validateStatueInstallateurGlobale(
        bodyFormData
      );
      if (response) {
        this.loading = false;
        this.hideModal('validate-statut-installateur-modal');
        let text =
          '<div style="dispaly:flex"><div>Statut validé avec succès  : ' +
          response.message.success +
          '\n' +
          '\n' +
          '<div>Statut non modifié : ' +
          response.message.errors +
          '</div>';
        this.$alert('', text, 'success').then(() => {
          this.$emit('reloadData');
        });
      }
    }
  },
  computed: {
    ...mapGetters(['getUserData']),
    computedFields() {
      switch (this.filterType) {
        case 'NOVA':
          return 'operationnel_id';
        case 'CACTUS':
          return this.curentInterface == 'frais'
            ? 'commercial_id'
            : 'cactus_id';
        case 'AXELIUS':
          return 'marketing_id';
        case 'ISO 33':
          return 'technique_id';
        case 'CERTILIA':
          return 'rge_id';
        case 'GE CDG':
          return 'CDG_id';
        case 'SAMI-B':
          return 'administratif_sav_id';
        case 'WEB ABONDANCE':
          return 'dsi_id';
        case 'CORPEDYS':
          return 'juridique_id';
        case 'ALTEO France':
          return 'alger_id';
        case 'NOVAE MAIRIE':
          return 'mairie_id';
        case 'GSE':
          return 'maintenance_pac_id';
        case 'UPCUBE TECHNOLOGIES':
          return 'upcube_id';
        case 'LAV':
          return 'externe_id';
        case 'ALTEO FRANCE Régie Commerciale INTERNE':
          return 'interne_id';
        case 'ALTEO FRANCE Supervision':
          return 'alteo_id';
        case 'ALTEO FRANCE Support Commercial':
          return 'commercial_id';
        case 'BE B KYNAN':
          return 'facture_kynan_id';
        case 'BE B ALTEO':
          return 'facture_alteo_id';
        case 'KYNAN - ISO 33 - ISAUDIT':
          return 'facture_id';
        case 'UPCUBE TECHNOLOGIES':
          return 'upcube_id';
        case 'IHS':
          return 'ihs_id';
        case 'Alteo contrôle qualité':
          return 'alteo_control_gestion_id';
        default:
          return this.filterType;
      }
    },
    computedRole() {
      if (
        this.getUserData?.role &&
        ((this.getUserData?.role == 'responsable_filiale' &&
          this.filterType != 'IHS') ||
          (this.getUserData?.role == 'responsable_acteo' &&
            this.filterType == 'IHS') ||
          this.getUserData?.role == 'admin')
      ) {
        return 'installateur';
      } else {
        return 'facture';
      }
    },
    computedStatueToUpdate() {
      if (
        this.getUserData?.role &&
        ((this.getUserData?.role == 'responsable_filiale' &&
          this.filterType != 'IHS') ||
          (this.getUserData?.role == 'responsable_acteo' &&
            this.filterType == 'IHS') ||
          this.getUserData?.role == 'admin')
      ) {
        return 'installateur';
      } else {
        return '';
      }
    },
    computedListType() {
      if (
        this.getUserData?.role &&
        ((this.getUserData?.role == 'responsable_filiale' &&
          this.filterType != 'IHS') ||
          (this.getUserData?.role == 'responsable_acteo' &&
            this.filterType == 'IHS') ||
          this.getUserData?.role == 'admin')
      ) {
        return this.ListType.filter(
          item => item.value == 'paye' || item.value == 'a paye'
        );
      } else {
        return this.ListType;
      }
    }
  }
};
</script>

<style scoped lang="scss"></style>
<style lang="scss">
.align-item-validate-statut-installateur-modal {
  display: flex;
  .form-group {
    width: 50%;
  }
}
.modal-validation-statut-frais {
  .d-block,
  #fieldset-horizontal,
  #fieldset-horizontal-type {
    font-size: 12px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    text-align: start;
    color: #2a2a2a;
    margin-left: 3%;
    margin-top: 8px;
    margin-bottom: 1px;
  }
  .form-modal-custom-style {
    text-align: center;
  }
}
</style>
